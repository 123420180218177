import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/layout'
import Slider from '../components/slider'
import BioSection from '../components/biographie'
import ContactSection from '../components/contact'
import MediaSection from '../components/media'
import PressSection from '../components/press'
import DatesSection from '../components/dates'
import PromoSection from '../components/promo'
import { Helmet } from "react-helmet"
import 'moment/locale/de';
import '../styles/index.scss'

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Anni Perka</title>
            </Helmet>
            <section id="home">
                <Slider></Slider>
            </section>
            <PromoSection></PromoSection>
            <section id="dates">
                <DatesSection></DatesSection>
            </section>
            <section id="press">
                <PressSection></PressSection>
            </section>
            <section id="media">
                <MediaSection></MediaSection>
            </section>
            <section id="bio">
                <BioSection></BioSection>
            </section>
            <section id="booking">
                <ContactSection></ContactSection>
            </section>
        </Layout>
    )
}

export default IndexPage
