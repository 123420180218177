import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './section.module.scss'
import BackgroundImage from 'gatsby-background-image'
import { Container, Row, Col } from 'react-bootstrap'

const query = graphql`
query {
    allStrapiVideo {
        nodes {
            strapiId
        videolink
        }
    }
    strapiMedien {
        hintergrundbild {
            childImageSharp {
            fluid(maxWidth: 1900, quality: 85) {
                ...GatsbyImageSharpFluid_noBase64
            }
            }
        }
        Titel
        }


}
`


const MediaSection = () => {
    const data = useStaticQuery(query);
    return (
        <BackgroundImage fluid={data.strapiMedien.hintergrundbild.childImageSharp.fluid}>
            <Container className="pt-4 pb-4">
                <div className={`${styles.mediaSection} ${styles.defaultSection} `}>
                    <Row>
                        <Col>
                            <h1 className="text-center">{data.strapiMedien.Titel}</h1>
                            <h2 className={styles.separator}></h2>
                            <hr className="mb-4"></hr>
                        </Col>
                    </Row>
                    <Row>
                        {
                            data.allStrapiVideo.nodes.map(video => {
                                return (
                                    <Col key={video.strapiId} lg="6" md="6" sm="12" className="mb-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe src={video.videolink} frameBorder="0" allow="autoplay; encrypted-media"
                                                allowFullScreen></iframe>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default MediaSection
