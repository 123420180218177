import React from 'react'
import { Carousel } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import Image from 'gatsby-image'
import styles from './slider.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Container, Row, Col } from 'react-bootstrap'

const query = graphql`
query {
    allStrapiSlide(filter: {anzeigen: {eq: true}}) {
      edges {
        node {
          strapiId
          text
          bild {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    strapiMusik {
        bild {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
    }
  }
`



const SliderComponent = () => {
        const data = useStaticQuery(query);
        function buyLink() {
            if (typeof window !== "undefined") {
                if (window.fbq != null) {
                    window.fbq('track', 'InitiateCheckout', {
                        value: 1.29,
                        currency: 'EUR',
                    });
                }
            }
        }
        return (
            <div>
                <Carousel controls={false} interval={6500} indicators={false}>
                    {
                        data.allStrapiSlide.edges.map(slide => {
                            return (
                                //   <div key={slide.node.strapiId}>
                                <Carousel.Item>
                                    <Image fluid={slide.node.bild.childImageSharp.fluid} className="d-block w-100"></Image>
                                    {slide.node.text && slide.node.text.trim() !== "" &&
                                        <Carousel.Caption className={styles.carouselCaption}>
                                            <h4>{slide.node.text}</h4>
                                        </Carousel.Caption>
                                    }
                                    {/* <Carousel.Caption className={styles.carouselBuy}> */}
                                        {/* <Container className="text-center"> */}
                                            {/* <Row> */}
                                                {/* <Col sm="3" md="1" className="mr-3">
                                                    <div className={`${styles.buyBackground}`}>
                                                        <a href="https://amzn.to/2xAQX32" target="_blank" onClick={buyLink}>
                                                            <FontAwesomeIcon icon={fab.faAmazon} size="4x" />
                                                        </a>
                                                    </div>
                                                </Col> */}
                                                {/* <Col sm="3" md={{ span: 1 }}>
                                                    <div className={styles.buyBackground} onClick={buyLink}>
                                                        <a href="https://apple.co/3dIwwSq" target="_blank">
                                                            <FontAwesomeIcon icon={fab.faApple} size="4x" />
                                                        </a>
                                                    </div>
                                                </Col> */}
                                            {/* </Row> */}
                                        {/* </Container> */}
                                    {/* </Carousel.Caption> */}
                                </Carousel.Item>
                                // </div>
                            )
                        })
                    }

                </Carousel>
                {/* <Container fluid className={styles.buyMobile}>
                    <Row className="pt-3">
                        <Col className="text-center">
                            <a href="https://amzn.to/2xAQX32" target="_blank" onClick={buyLink}>
                                <FontAwesomeIcon icon={fab.faAmazon} size="4x" />
                                <div>
                                    Bestellen bei Amazon
                            </div>
                            </a>
                        </Col>
                        <Col className="text-center">
                            <a href="https://apple.co/3dIwwSq" target="_blank" onClick={buyLink}>
                                <FontAwesomeIcon icon={fab.faApple} size="4x" />
                                <div>
                                    Bestellen bei iTunes
                            </div>
                            </a>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        )
}

export default SliderComponent
