import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './section.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import promoStyles from './promo.module.scss'
import BackgroundImage from 'gatsby-background-image'

const query = graphql`
query {
    strapiMusik {
      titel
      videolink
      beschreibung
      bild {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      link
      hintergrundbild {
          childImageSharp {
              fluid(maxWidth: 1500, quality: 85) {
                ...GatsbyImageSharpFluid_noBase64
              }
          }
      }
    }
  }
`

const PromoSection = () => {
    const data = useStaticQuery(query).strapiMusik;
    return (
        <BackgroundImage fluid={data.hintergrundbild.childImageSharp.fluid}>
            <Container fluid className="pt-3 pb-3">
                <Row className="justify-content-center">
                    <Col md="9" sm="9" lg="9" className="mb-2 mt-3">
                        <div className="embed-responsive embed-responsive-16by9 rounded shadow-lg">
                            <iframe width="480" height="277" src={data.videolink} frameBorder="0" allow="autoplay; encrypted-media"
                                allowFullScreen></iframe>
                        </div>
                    </Col>
                </Row>
                {/* Promo-Cards */}
                <Row className="mt-4">
                    <Col md="4" lg="4" sm="12">
                        <div className={`card shadow p-3 mb-4 bg-white rounded mx-auto text-center ${promoStyles.customCard}`}>
                            <Image fluid={data.bild.childImageSharp.fluid}></Image>
                            <div className="card-body text-center">
                                <h5 className="card-title">{data.titel}</h5>
                                <p className="card-text">{data.beschreibung}</p>
                                <a href={data.link} target="_blank" className="btn btn-primary">Hier erhältlich!</a>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="12">
                        <div className={`card shadow p-3 mb-4 bg-white rounded mx-auto text-center custom-card-spotify ${promoStyles.customCard}`}>
                            <h4>Anni Perka auf Spotify</h4>
                            <div className="card shadow p-3 mb-3 bg-white rounded">
                                <iframe src="https://open.spotify.com/embed/artist/4MyWwHAAULhyucj8jxwDit" className="rounded custom-spotify"
                                    width="auto" height="350" frameBorder="0" allowransparency="true" allow="encrypted-media"></iframe>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="12">
                        <div className={`card shadow p-3 mb-4 bg-white rounded mx-auto text-center custom-card-spotify ${promoStyles.customCard}`}>
                            <h4>Anni Perka auf Facebook</h4>
                            <div className="card shadow p-3 mb-3 bg-white rounded">
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fanniperka&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                    width="auto" height="350" scrolling="no" frameBorder="0"
                                    allowransparency="true" allow="encrypted-media"></iframe>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BackgroundImage>
    )
}

export default PromoSection
