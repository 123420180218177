import React from 'react'
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import styles from './section.module.scss'

const contentQuery = graphql`
    query aboutQuery {
        strapiUeberMich {
        inhalt
        titel
        hintergrundbild{
            childImageSharp{
                fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        }
    }
`


const BiographieSection = () => {
    const data = useStaticQuery(contentQuery)
    return (
        <BackgroundImage fluid={data.strapiUeberMich.hintergrundbild.childImageSharp.fluid}>
            <Container className="pt-4 pb-4">
                <div className={`${styles.bioSection} ${styles.defaultSection}`}>
                <Row>
                    <Col>
                        <h1 className="text-center">{data.strapiUeberMich.titel}</h1>
                        <h2 className={styles.separator}></h2>
                        <hr className="mb-4"></hr>
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.bioSectionText}>
                        <ReactMarkdown source={data.strapiUeberMich.inhalt}></ReactMarkdown>
                    </Col>
                </Row>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default BiographieSection
