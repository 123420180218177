import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import styles from './section.module.scss'
import dateStyles from './dates.module.scss'
import Moment from 'react-moment';
import ReactMarkdown from "react-markdown"

const query = graphql`
query {
    allStrapiDates(sort: {order: ASC, fields: datum}, filter: {anzeigen: {eq: true}}) {
        nodes {
          strapiId
          anzeigen
          titel
          datum
          beschreibung
        }
      }
      strapiTermine {
        titel
        hintergrundbild {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
}
`

const DatesSection = () => {
  const data = useStaticQuery(query);
  let showSection = false;
  data.allStrapiDates.nodes.forEach(date => {
    if (date.anzeigen) {
      showSection = true;
    }
  })

  return (
    <div>
      {showSection &&
        <BackgroundImage fluid={data.strapiTermine.hintergrundbild.childImageSharp.fluid} >
          <Container fluid className="pt-4 pb-4">
            <div className={`${styles.defaultSection} ${styles.datesSection}`}>
              <Row>
                <Col>
                  <h1 className="text-center">{data.strapiTermine.titel}</h1>
                  <h2 className={styles.separator}></h2>
                </Col>
              </Row>
              {
                data.allStrapiDates.nodes.map(date => {
                  return (

                    <Row key={date.strapiId} className={`${dateStyles.newsRow}`}>
                      <Col lg="1" md="2" sm="12" className={`${dateStyles.newsDates}`}>
                        <h1 className={dateStyles.noDesktop}>{date.titel}</h1>
                        <h2 className={dateStyles.noMobile}>
                          <Moment locale="de" format="D">
                            {date.datum}
                          </Moment>
                        </h2>
                        <p className={dateStyles.noMobile}>
                          <Moment locale="de" format="MMM">
                            {date.datum}
                          </Moment>
                        </p>
                        <p className={dateStyles.noDesktop}>
                          <Moment locale="de" format="LL">
                            {date.datum}
                          </Moment>
                        </p>
                      </Col>
                      <Col lg="11" md="10" sm="12" className={`${dateStyles.newsContent}`}>
                        <h1 className={dateStyles.noMobile}>{date.titel}</h1>
                        <ReactMarkdown className={dateStyles.newContentDescription} source={date.beschreibung}></ReactMarkdown>
                      </Col>
                    </Row>
                  )
                })
              }
            </div>
          </Container>
        </BackgroundImage>
      }
    </div>
  )
}

export default DatesSection
