import React from 'react'
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './section.module.scss'

const contentQuery = graphql`
query contactQuery {
    strapiContact {
      inhalt
      titel
      strapiId
      hintergrundbild {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

const ContactSection = () => {
    const data = useStaticQuery(contentQuery)
    return (
        <BackgroundImage fluid={data.strapiContact.hintergrundbild.childImageSharp.fluid}>
            <Container className={`pt-4 pb-4`}>
                <div className={`${styles.defaultSection} ${styles.contactMainSection} ${styles.contactSection}`}>
                    <Row>
                        <Col>
                            <h1 className="text-center">{data.strapiContact.titel}</h1>
                            <h2 className={styles.separator}></h2>
                            <hr className="mb-4"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.contactSection}>
                            <ReactMarkdown source={data.strapiContact.inhalt}></ReactMarkdown>
                        </Col>
                        <Col md="12" lg="12" className="text-center mb-2">
                            <a href="mailto:booking@anniperka.de"><button type="button" class="btn btn-info">Booking</button></a>
                        </Col>
                    </Row>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default ContactSection
