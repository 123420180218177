import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './section.module.scss'

const query = graphql`
    query MyQuery {
        strapiPresse {
            titel
            bild1 {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            bild2 {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            hintergrundbild {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 1900, quality: 70) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
          }
    }
`

const PressSection = () => {
    const data = useStaticQuery(query);
    return (
        <BackgroundImage fluid={data.strapiPresse.hintergrundbild.childImageSharp.fluid}>
            <Container className="pt-4 pb-4">
                <div className={`${styles.defaultSection} ${styles.pressSection}`}>
                    <Row>
                        <Col>
                            <h1 className="text-center">{data.strapiPresse.titel}</h1>
                            <h2 className={styles.separator}></h2>
                            <hr className="mb-4"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Image fluid={data.strapiPresse.bild1.childImageSharp.fluid} className="img-thumbnail img-fluid rounded"></Image>
                            <div className="col-lg-12 text-center mb-2 mt-3">
                                <a href={data.strapiPresse.bild1.publicURL} download="perka_presse1"><button type="button" className="btn btn-info">Download</button></a>
                            </div>
                        </Col>
                        <Col md="6">
                            <Image fluid={data.strapiPresse.bild2.childImageSharp.fluid} className="img-thumbnail img-fluid rounded"></Image>
                            <div className="col-lg-12 text-center mb-2 mt-3">
                                <a href={data.strapiPresse.bild2.publicURL} download="perka_presse2"><button type="button" className="btn btn-info">Download</button></a>
                            </div>
                        </Col>
                        {/* <Col md="3">
                            <Image fluid={data.strapiPresse.bild3.childImageSharp.fluid} className="img-thumbnail img-fluid rounded"></Image>
                            <div className="col-lg-12 text-center mb-2 mt-3">
                                <a href={data.strapiPresse.bild3.publicURL} download="perka_presse3"><button type="button" className="btn btn-info">Download</button></a>
                            </div>
                        </Col>
                        <Col md="3">
                            <Image fluid={data.strapiPresse.bild4.childImageSharp.fluid} className="img-thumbnail img-fluid rounded"></Image>
                            <div className="col-lg-12 text-center mb-2 mt-3">
                                <a href={data.strapiPresse.bild4.publicURL} download="perka_presse4"><button type="button" className="btn btn-info">Download</button></a>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default PressSection
